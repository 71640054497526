import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {

    // document.addEventListener("turbo:frame-render", function (e) {
    //   // console.log('turbo:frame-render');
    //   bindContractEvents();
    // });

    bindAssignmentEvents();
    bindSocialSecurityLevelEvents();

    function bindAssignmentEvents(){
      const form = $('#crewman_assignment_form');
      if(form == undefined){
        return;
      }

      bindDocumentKindEvents();
    }

    function bindDocumentKindEvents(){
      let documentKindSelect = $('#crewman_assignment_document_kind');
      let selectedDocumentKind = documentKindSelect.val();
      handleDocumentKindSelect(selectedDocumentKind);

      documentKindSelect.on('change', function(){
        selectedDocumentKind = $(this).val();
        handleDocumentKindSelect(selectedDocumentKind);
      });

      function handleDocumentKindSelect(documentKind){
        if(documentKind == 'annex'){
          $('.js-annex-fields').removeClass('display-none');
          $('.js-annex-fields').find(':input, select').prop('disabled', false);

          $('.js-contract-fields').addClass('display-none');
          $('.js-contract-fields').find(':input, select').prop('disabled', true);
        }else{
          $('.js-annex-fields').addClass('display-none');
          $('.js-annex-fields').find(':input, select').prop('disabled', true);

          $('.js-contract-fields').removeClass('display-none');
          $('.js-contract-fields').find(':input, select').prop('disabled', false);
        }
      } 
    }

    function bindSocialSecurityLevelEvents(){
      let minSocialSecurityLevelSelected = $('#min_social_security_level').is(":checked");
      handleMinSocialSecurityLevel(minSocialSecurityLevelSelected);

      $('#min_social_security_level').on('change', function(){
        minSocialSecurityLevelSelected = $(this).is(":checked");
        handleMinSocialSecurityLevel(minSocialSecurityLevelSelected);
      })
       

      function handleMinSocialSecurityLevel(minSocialSecurityLevelSelected){
        if(minSocialSecurityLevelSelected){
          $('.js-social-security-level').addClass('display-none');
          $('.js-social-security-level').find(':input, select').prop('disabled', true);
        }else{
          $('.js-social-security-level').removeClass('display-none');
          $('.js-social-security-level').find(':input, select').prop('disabled', false);
        } 
      }
    }

  }
}
