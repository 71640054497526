import { Controller } from "@hotwired/stimulus"
import Select2 from "select2/dist/js/select2.min.js";

export default class extends Controller {
  connect() {
    Select2();

    document.addEventListener("turbo:before-cache", function () {
      unbindSelect2();
      // unbindExportDocumentsForm();
    });

    // document.addEventListener("turbo:frame-render", function (e) {
    //   // console.log('turbo:frame-render');
    //   bindSelect2();
    // });

    // focus on the search field when select2 is opened
    $(document).on("select2:open", () => {
      document
        .querySelector(".select2-container--open .select2-search__field")
        .focus();
    });


    bindChangeLocale();
    bindSelect2();
    autoSubmitFormOnInputChange();

    // bindExportDocumentsForm();

    // function bindExportDocumentsForm(){
    //   // $('.js-export-documents-btn').on('click', function(){
    //   //   $(this).addClass('disabled');
    //   // })

    //   // Submit with javascript - the submit button will be disabled until the form submition is completed
    //   $('.js-export-documents').on('submit', function(e){
    //     e.preventDefault();
    //     let formAction = $(this).attr("action");
    //     let submitButton = $(this).find(':submit');
    //     submitButton.addClass('disabled');
    //     console.log(submitButton);

    //     fetch(formAction)
    //     .then(resp => resp.blob())
    //     .then(blob => {
    //       const url = window.URL.createObjectURL(blob);
    //       const a = document.createElement('a');
    //       a.style.display = 'none';
    //       a.href = url;
    //       a.download = 'export.rar';
    //       document.body.appendChild(a);
    //       a.click();
    //       window.URL.revokeObjectURL(url);
    //       submitButton.removeClass('disabled');
    //     })
    //     .catch(() => alert('Something went wrong!'));
    //   })
    // }

    // function unbindExportDocumentsForm(){
    //   $('.js-export-documents').off('submit');
    // }

    function unbindSelect2() {
      $("select.select2-hidden-accessible").select2("destroy");
    }

    function bindChangeLocale() {
      let selectedLocale = $(".js-change-locale.selected").data("locale");
      selectedLocale ||= "en";
      // console.log(selectedLocale);

      hideOtherLocales();

      $(".js-change-locale").on("click", function () {
        $(".js-change-locale").removeClass("selected");
        $(this).addClass("selected");
        selectedLocale = $(this).data("locale");

        hideOtherLocales();
      });

      function hideOtherLocales() {
        let localeFields = $(".js-locale-fields");

        for (var i = localeFields.length - 1; i >= 0; i--) {
          if (localeFields[i].classList.contains(selectedLocale)) {
            localeFields[i].classList.remove("display-none");
          } else {
            localeFields[i].classList.add("display-none");
          }
        }
      }
    }

    function bindSelect2() {
      let elements = $(".select2");

      // the event gets binded when the page is loaded and when a modal is opened
      let elementsWithoutSelect2Event = [];

      for (var i = elements.length - 1; i >= 0; i--) {
        let element = elements[i];
        if (element.dataset.select2Id == undefined) {
          elementsWithoutSelect2Event.push(element);
          let options = { width: '100%' };
          if(element.classList.contains('error')){
            options.selectionCssClass = 'error';
          }

          options.templateResult = function(data, container){
            if (data.element) {
              $(container).addClass($(data.element).attr("class"));
            }
            return data.text;
          }

          $(element).select2(options).on('select2:select', function(){
            // for auto submit form fields
            if(element.dataset.autoSubmit == '1'){
              let parentElements = $(element).parents();
              for (var i = 0; i < parentElements.length; i++) {
                if(parentElements[i].tagName.toLowerCase() == 'form'){
                  $(parentElements[i]).submit();
                }
              }
            }
          });

          $(element).select2(options).on('select2:unselect', function(){
            // for auto submit form fields
            if(element.dataset.autoSubmit == '1'){
              let parentElements = $(element).parents();
              for (var i = 0; i < parentElements.length; i++) {
                if(parentElements[i].tagName.toLowerCase() == 'form'){
                  $(parentElements[i]).submit();
                }
              }
            }
          });

          $(element).on('select2:open', function(){
            // Allow editing the autocomplete value
            if($(element).data('tags') == true){
              $('.select2-container--open').find('.select2-search__field').val(element.value);
            }
          });


          if(element.id == 'crewman_movement_company_id'){
            // bindNewContractCompanySelect(element, $('#crewman_movement_vessel_id'));
          }
          if(element.id == 'vessel_company_id'){
            bindVesselCompanyBranchSelect(element, $('#vessel_company_branch_id'));
            bindVesselOwnerSelect(element, $('#vessel_vessel_owner_id'));
            bindVesselManagerSelect(element, $('#vessel_vessel_manager_id'));
            bindVesselPiInsuranceSelect(element, $('#vessel_pi_insurance_id'));
          }
          if(element.id == 'crewman_assignment_company_id'){
            bindNewContractCompanySelect(element, $('#crewman_assignment_vessel_id'));
          }

          if(element.id == 'payroll_item_crewman_assignment_id'){
            bindPayrollAssignmentSelect(element);
          }

          if(element.id == 'crewman_status_change_status'){
            bindStatusChangeSelect(element);
          }
        }
      }

      for (var i = elementsWithoutSelect2Event.length - 1; i >= 0; i--) {
        let element = elementsWithoutSelect2Event[i];
        let autocompleteUrl = element.dataset.autocomplete;

        // load the data once with ajax when the select2 is opened
        if (autocompleteUrl != undefined) {
          $(element).on("select2:opening", function (e) {
            // console.log($(this))
            let currentTarget = e.currentTarget;
            // console.log($(currentTarget).data("autocomplete"))
            // console.log(currentTarget);

            if ($(currentTarget).data("data-loaded") != "1") {
              // The autocomplete url may have changed
              autocompleteUrl = $(currentTarget).data("autocomplete");
              let blankOptionText = $(currentTarget).data("blankOptionText") || "";
              // e.preventDefault();
              console.log("fetching data");
              loadAutocompleteOptions(currentTarget, autocompleteUrl, blankOptionText);
            }
          });
        }
      }
    }

    function bindStatusChangeSelect(element){
      let latestAssignmentStartDate = $('#latest_assignment_start_date').val();
      let latestAssignmentEndDate = $('#latest_assignment_end_date').val();
      console.log(latestAssignmentStartDate)
      let dateInput = $('.js-status-change-date')

      $(element).on('select2:select', function (e) {
        let data = e.params.data;
        let status = data.id;

        if(status == 'active'){
          dateInput.val(latestAssignmentStartDate);
        }else if (status == 'newcomer'){
          dateInput.val(null);
        }else{
          dateInput.val(latestAssignmentEndDate);
        }
      });
    }

    function bindPayrollAssignmentSelect(element){
      $(element).on('select2:select', function (e) {
        let data = e.params.data;
        let extraOptionsString = data.element.dataset.extra;
        if(extraOptionsString !== undefined && extraOptionsString !== null){
          let extraOptions = JSON.parse(extraOptionsString);
          console.log(extraOptions);
          $('#payroll_item_overtime_salary').val(extraOptions.onboard_salary_eur);
          // $('.js-deductable-travel-allowance-sum').val(extraOptions.travel_allowance_sum);
          // $('.js-deductable-travel-allowance-sum-bgn').val(extraOptions.travel_allowance_sum_bgn);
          // $('.js-deductable-travel-allowance-sum').trigger('change');
          // $('.js-deductable-travel-allowance-sum-bgn').trigger('change');
        }else{
          // $('.js-deductable-travel-allowance-sum').val(0.0);
          // $('.js-deductable-travel-allowance-sum-bgn').val(0.0);
          // $('.js-deductable-travel-allowance-sum').trigger('change');
          // $('.js-deductable-travel-allowance-sum-bgn').trigger('change');
        }
      });
    }

    function bindNewContractCompanySelect(element, vesselSelect){
      // When the value of a company is changed, refresh the vessels select
      let config = $('#main-config').data('config') || {};
      let demeId = config.deme_id;
      
      let selectedCompanyId = null;
      // let vesselSelect = $('#crewman_movement_vessel_id');

      selectedCompanyId = $(element).val();
      $(element).on('select2:select', function (e) {
        let data = e.params.data;

        if (selectedCompanyId != data.id) {
            // reset the office select
            vesselSelect.data("autocomplete", '/autocomplete/company_vessel?company_id=' + data.id);
            vesselSelect.data("data-loaded", '0');
            vesselSelect.val(null).trigger('change');
        }
        selectedCompanyId = data.id;

        if(selectedCompanyId == demeId){
          $('.js-deme-fields').removeClass('display-none');
        }else{
          $('#min_social_security_level').prop('checked','checked')
          $('#min_social_security_level').trigger('change');
          $('.js-deme-fields').addClass('display-none');
        }
      });
    }

    function bindVesselCompanyBranchSelect(element, vesselSelect){
      // When the value of a company is changed, refresh the vessels select
      let selectedCompanyId = null;
      // let vesselSelect = $('#crewman_movement_vessel_id');

      selectedCompanyId = $(element).val();
      $(element).on('select2:select', function (e) {
        let data = e.params.data;

        if (selectedCompanyId != data.id) {
            // reset the office select
            vesselSelect.data("autocomplete", '/autocomplete/company_branch?company_id=' + data.id);
            vesselSelect.data("data-loaded", '0');
            vesselSelect.val(null).trigger('change');
        }
        selectedCompanyId = data.id;
      });
    }

    function bindVesselManagerSelect(element, managerSelect){
      // When the value of a company is changed, refresh the vessels select
      let selectedCompanyId = null;
      // let vesselSelect = $('#crewman_movement_vessel_id');

      selectedCompanyId = $(element).val();
      $(element).on('select2:select', function (e) {
        let data = e.params.data;

        if (selectedCompanyId != data.id) {
            // reset the office select
            managerSelect.data("autocomplete", '/autocomplete/vessel_manager?company_id=' + data.id);
            managerSelect.data("data-loaded", '0');
            managerSelect.val(null).trigger('change');
        }
        selectedCompanyId = data.id;
      });
    }

    function bindVesselOwnerSelect(element, ownerSelect){
      // When the value of a company is changed, refresh the vessels select
      let selectedCompanyId = null;
      // let vesselSelect = $('#crewman_movement_vessel_id');

      selectedCompanyId = $(element).val();
      $(element).on('select2:select', function (e) {
        let data = e.params.data;

        if (selectedCompanyId != data.id) {
            // reset the office select
            ownerSelect.data("autocomplete", '/autocomplete/vessel_owner?company_id=' + data.id);
            ownerSelect.data("data-loaded", '0');
            ownerSelect.val(null).trigger('change');
        }
        selectedCompanyId = data.id;
      });
    }

    function bindVesselPiInsuranceSelect(element, insuranceSelect){
      let selectedCompanyId = null;
      selectedCompanyId = $(element).val();
      
      $(element).on('select2:select', function (e) {
        let data = e.params.data;

        if (selectedCompanyId != data.id) {
            // reset the office select
            insuranceSelect.data("autocomplete", '/autocomplete/pi_insurance?company_id=' + data.id);
            insuranceSelect.data("data-loaded", '0');
            insuranceSelect.val(null).trigger('change');
        }
        selectedCompanyId = data.id;
      });
    }


    function loadAutocompleteOptions(element, autocompleteUrl, blankOptionText) {
      console.log("loadAutocompleteOptions");
      $.ajax({
        type: "GET",
        url: autocompleteUrl,
        data: {}, // Any data that is needed to pass to the controller
        dataType: "json",
        success: function (returnedData) {
          //console.log(returnedData)
          let options = returnedData.results;

          // prepend empty option
          options.unshift({ id: "", text: blankOptionText });

          // let selectedOption = $(element).find(":selected");
          // console.log(selectedOption.text())

          // if (selectedOption.val() != "") {
          //   // add the selected options if it's not in the returened list
          //   var found = false;
          //   for (var i = 0; i < options.length; i++) {
          //     if (options[i].id == selectedOption.val()) {
          //       options[i].selected = true;
          //       found = true;
          //       break;
          //     }
          //   }

          //   if (!found) {
          //     options.unshift({
          //       id: selectedOption.val(),
          //       text: selectedOption.text(),
          //       selected: true,
          //     });
          //   }
          // }

          let selectedOptions = $(element).find(":selected");
          if (selectedOptions.length > 0){
            // add the selected options if it's not in the returened list
            let selectedOption = null;
            let found = false;
            let selectedOptionsLength = selectedOptions.length;

            for (var ii = 0; ii < selectedOptionsLength; ii++) {
              selectedOption = selectedOptions[ii];
              found = false;

              for (var i = 0; i < options.length; i++) {
                if (options[i].id == selectedOption.value) {
                  options[i].selected = true;
                  found = true;
                  break;
                }
              }

              if (!found) {
                options.unshift({
                  id: selectedOption.value,
                  text: selectedOption.text,
                  selected: true,
                });
              }
            }
          }

          $(element).empty();

          let extraOptions = '';
          for (var i = 0; i < options.length; i++) {
            extraOptions = JSON.stringify(options[i].extra || {});
            // $(element).append(options[i]);
            if (options[i].selected) {
              $(element).append(
                $("<option data-extra='" + extraOptions + "'></option>")
                  .attr("value", options[i].id)
                  .text(options[i].text)
                  .attr("selected", "selected")
              );
            } else {
              $(element).append(
                $("<option data-extra='" + extraOptions + "'></option>")
                  .attr("value", options[i].id)
                  .text(options[i].text)
              );
            }
          }

          $(element).data("data-loaded", "1");
          $(element).trigger("change");
          $(element).select2("close");
          $(element).select2("open");
        },
      });
    }

    function autoSubmitFormOnInputChange(){
      let autoSubmitFiles = $('.js-auto-submit-form');
      for (var i = autoSubmitFiles.length - 1; i >= 0; i--) {
        let file = $(autoSubmitFiles[i]);
        if(file.data('change-attached') != 'attached'){

          file.on('change', function(){
            let parentElements = $(this).parents();
            for (var i = 0; i < parentElements.length; i++) {
              if(parentElements[i].tagName.toLowerCase() == 'form'){
                $(parentElements[i]).submit();
              }
            }
          });

          file.data('change-attached', 'attached');
        }
      }
    };

  }
}
