import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="modal"
export default class extends Controller {
  connect() {
    console.log(1)

    let currencySelect = $('.js-currency');
    let currency = currencySelect.val();
    let container = $('.js-payroll-template');
    currencySelected(currency, container);

    function currencySelected(currency, container){
      let displayCurrency = $(container).find('.js-selected-currency');
      let unitSumBgnInput = $(container).find('.js-unit-sum-bgn');
      let unitSumInput = $(container).find('.js-unit-sum-eur');

      let bgnInputs = $(container).find('.js-sum-input-bgn');
      let eurInputs = $(container).find('.js-sum-input-eur');

      displayCurrency.html(currency.toUpperCase());

      // $('.js-payroll-template').addClass('display-none');
      // $('.js-payroll-template').find(':input, select').prop('disabled', true);

      if(currency == 'eur'){
        unitSumBgnInput.prop('disabled', true);
        unitSumBgnInput.parent().addClass('display-none');
        unitSumInput.prop('disabled', false);
        unitSumInput.parent().removeClass('display-none');

        bgnInputs.prop('disabled', true);
        bgnInputs.parent().addClass('display-none');
        eurInputs.prop('disabled', false);
        eurInputs.parent().removeClass('display-none');

      }else if(currency == 'bgn'){
        unitSumBgnInput.prop('disabled', false);
        unitSumBgnInput.parent().removeClass('display-none');
        unitSumInput.prop('disabled', true);
        unitSumInput.parent().addClass('display-none');

        bgnInputs.prop('disabled', false);
        bgnInputs.parent().removeClass('display-none');
        eurInputs.prop('disabled', true);
        eurInputs.parent().addClass('display-none');
      }else{
        unitSumBgnInput.prop('disabled', true);
        unitSumBgnInput.parent().addClass('display-none');
        unitSumInput.prop('disabled', true);
        unitSumInput.parent().addClass('display-none');

        bgnInputs.prop('disabled', true);
        bgnInputs.parent().addClass('display-none');
        eurInputs.prop('disabled', true);
        eurInputs.parent().addClass('display-none');

      };
    };

    currencySelect.on('change', function(){
      currency = $(this).val();
     // let container = $(this).parents('.js-payroll-template');
      currencySelected(currency, container);
    });
  }
}
