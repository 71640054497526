import flatpickr from "flatpickr";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect/index.js"

import { Controller } from "@hotwired/stimulus"

let fpVar = null;
let fpVar2 = null;

export default class extends Controller {
  connect() {
    fpVar = flatpickr('.js-flatpickr', {
      altInput: true,
      altFormat: "d/m/Y",
      dateFormat: 'Y-m-d'
    });

    fpVar2 = flatpickr('.js-flatpickr-month', {
      disableMobile: true,
      plugins: [
        new monthSelectPlugin({
            shorthand: true,
            dateFormat: "m/Y",
            altFormat: "F Y",
            theme: "material_blue"
        })
      ]
    })
  }

  disconnect() {
    if (fpVar !== undefined && fpVar !== null &&  fpVar.length > 0) {
      try {
        for (var i = fpVar.length - 1; i >= 0; i--) {
          fpVar[i].destroy();
        }
        fpVar = null;
      } catch (error) {
        console.error('Flatpickr destruction failed. Error:');
        console.error(error);
      }
    }

    if (fpVar2 !== undefined && fpVar2 !== null &&  fpVar2.length > 0) {
      try {
        for (var i = fpVar2.length - 1; i >= 0; i--) {
          fpVar2[i].destroy();
        }
        fpVar2 = null;
      } catch (error) {
        console.error('Flatpickr destruction failed. Error:');
        console.error(error);
      }
    }


    // let flatpickrElements = $('.js-flatpickr, .js-datetime-picker');

    // let fp;
    // flatpickrElements.each(function() {
    // console.log($( this ));

    // fp = $( this )._flatpickr;
    // if(fp !== undefined){
    //   fp.destroy();
    // }
  //});
  }
}
