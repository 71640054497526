import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="modal"
export default class extends Controller {
  connect() {

    let unitSumBgnInput = $('#vessel_expense_unit_sum_bgn');
    let unitSumInput = $('#vessel_expense_unit_sum');
    let currencySelect = $('#vessel_expense_currency');
    let selectedCurrencyOption = $( "#vessel_expense_currency option:selected" );
    let currency = selectedCurrencyOption.val();

    currencySelected(currency);

    currencySelect.on('change', function(){
      currency = $(this).val();
      currencySelected(currency);
    });


    function currencySelected(currency){
      let selectedTemplate = '';

      $('.js-payroll-template').addClass('display-none');
      $('.js-payroll-template').find(':input, select').prop('disabled', true);

      if(currency == 'eur'){
        unitSumBgnInput.prop('disabled', true);
        unitSumBgnInput.parent().addClass('display-none');

        unitSumInput.prop('disabled', false);
        unitSumInput.parent().removeClass('display-none');

      }else if(currency == 'bgn'){
        unitSumBgnInput.prop('disabled', false);
        unitSumBgnInput.parent().removeClass('display-none');

        unitSumInput.prop('disabled', true);
        unitSumInput.parent().addClass('display-none');
      };
    };

    // unitSumBgnInput.on('input', function(){
    //   // let selectedKindOption = $( "#payroll_item_kind option:selected" );
    //   let sumBgn = $(this).val();

    //   if(sumBgn != '' && sumBgn != undefined && sumBgn > 0){
    //     let sumEur = (sumBgn / 1.95583)
    //     unitSumInput.val(sumEur);
    //   }
      
    // });

    // unitSumInput.on('input', function(){
    //   let sumEur = $(this).val();

    //   if(sumEur != '' && sumEur != undefined && sumEur > 0){
    //     let sumBgn = (sumEur * 1.95583)
    //     unitSumBgnInput.val(sumBgn);
    //   }

    // });
  }
}
