import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="modal"
export default class extends Controller {
  connect() {
    let body = document.querySelector("body");
    body.classList.add("scroll-disabled");
  }

  hideModal() {
    // enable scroll on modal close
    let body = document.querySelector("body");
    body.classList.remove("scroll-disabled");
    this.element.parentElement.removeAttribute("src"); // it might be nice to also remove the modal SRC
    this.element.remove();
  }

  // hide modal on successful form submission
  // action: "turbo:submit-end->modal#submitEnd"
  submitEnd(e) {
    if (e.detail.success) {
      console.log(e)
      let body = document.querySelector("body");
      body.classList.remove("scroll-disabled");
      this.hideModal();
    }else{
      console.log('fail!')
      console.log(e)
      const response = e.detail.fetchResponse.response;
      const statusCode = response.status;
      console.log(statusCode)
      if(statusCode != 422){
         alert('Съжаляваме, нещо се обърка!')
      }
    }
  }
}
