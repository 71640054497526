import { Controller } from "@hotwired/stimulus";
// import "@hotwired/turbo-rails"

export default class extends Controller {
  connect () {
    bindExportColumns();

    const checkbox = $('#crewman_current_address_same_as_permanent');
    let currentAddressIsSame = checkbox.is(':checked');
    toggleCurrentAddressFields(currentAddressIsSame);

    checkbox.on('change', function(){
    	currentAddressIsSame = $(this).is(':checked');
    	toggleCurrentAddressFields(currentAddressIsSame);
    })

    function toggleCurrentAddressFields(currentAddressIsSame){
    	if(currentAddressIsSame){
    		$('.js-current-address-fields').addClass('display-none');
    	}else{
    		$('.js-current-address-fields').removeClass('display-none');
    	}
    }

    function bindExportColumns(){
        let counter = $('.js-export-counter');

        $('.js-export-column').on('change', function(){
            let count = $('.js-export-column:checkbox:checked').length;
            counter.html(count);
        })
    }
  }
}