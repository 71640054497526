/*
    @created by Petar Dimitrov
    @last-updated by Petar Dimitrov on 26/10/2023
    
*/

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
	connect() {
		'use strict';

		let anexDropdown = $('.document-list');

		// Function to remove 'showed' class and overflow
		function resetDropdown() {
			$('.document-list').removeClass('showed');
			$('.wrap-table100').removeClass('overflow-unset');
		}

		// Function to open anex dropdown
		function openDropdown() {
			if ($('.document-list').hasClass('showed')) {
				resetDropdown();
			}
			let documentList = $(this).next();
			$(documentList).toggleClass('showed');
			$('.wrap-table100').toggleClass('overflow-unset');
		}
		$('.js-open-dropdown').on('click', openDropdown);

		// Function to close anex dropdown
		function closeDropdown() {
			let documentList = $(this).closest('.document-list');
			$(documentList).removeClass('showed');
			$('.wrap-table100').removeClass('overflow-unset');
		}
		$('.js-close-dropdown').on('click', closeDropdown);

		$(document).on('mouseup', function (e) {
			// if the target of the click is neither the container, nor a descendant of the container
			if (!anexDropdown.is(e.target) && anexDropdown.has(e.target).length === 0) {
				resetDropdown();
				closeDropdown();
			}
		});

		// open .js-export-fields-container function and rotate the arrow
		$('.js-export-fields-container').on('click', function () {
			$(this).toggleClass('flip');
			let exportFieldsContainer = $(this).parent().next();
			exportFieldsContainer.toggleClass('show');
		});

		// Menu Toggle
		let toggle = document.querySelector('.toggle');
		let closeSideMenu = document.querySelector('.js-close-menu');
		let navigation = document.querySelector('.navigation');
		let main = document.querySelector('.main');
		let body = $('body');

		toggle.onclick = function () {
			navigation.classList.toggle('active');
			main.classList.toggle('active');
		};

		$(closeSideMenu).on('click', function () {
			navigation.classList.toggle('active');
			main.classList.toggle('active');
		});

		// toggle body scroll on modal open
		let openModalBtn = $('.js-open-modal');
		openModalBtn.on('click', function () {
			body.addClass('scroll-disabled');
		});

		/* Tabs */
		var clickedTab = $('.tabs > .active');
		var tabWrapper = $('.tab__content');
		var activeTab = tabWrapper.find('.active');
		var activeTabHeight = activeTab.outerHeight();

		activeTab.addClass('display-list-item');

		setTimeout(function () {
			var activeTabHeight = activeTab.outerHeight();
			tabWrapper.height(activeTabHeight);
		}, 300);

		$('.tabs > li').on('click', function () {
			$('.tabs > li').removeClass('active');

			$(this).addClass('active');

			// Update clickedTab
			clickedTab = $('.tabs .active');
			if (clickedTab.data('tab') !== undefined) {
				alterActiveTabHistoryState(clickedTab.data('tab'));
			}

			activeTab.fadeOut(250, function () {
				$('.tab__content > li').removeClass('active', 'display-list-item');

				var clickedTabIndex = clickedTab.index();

				// Add class active to corresponding tab
				// eq() =  locate the selected elements directly and returns an element with specific index
				// $(".tab__content > li").eq(clickedTabIndex).addClass("active");
				$('.tab__content > li').eq(clickedTabIndex).addClass('active', 'display-list-item');

				activeTab = $('.tab__content > .active');
				activeTabHeight = activeTab.outerHeight();

				tabWrapper
					.stop()
					.delay(50)
					.animate(
						{
							height: activeTabHeight,
						},
						500,
						function () {
							activeTab.delay(50).fadeIn(250);
						}
					);
			});
		});

		function alterActiveTabHistoryState(activeTab) {
			let currentHref = window.location.href;
			let currentUrl = new URL(currentHref, 'https://starnav.eu');
			currentUrl.searchParams.set('active_tab', activeTab);

			history.replaceState(
				history.state,
				'',
				currentUrl.pathname + '?' + currentUrl.searchParams
			);

			let paginationLinks = $('.page-container > a');
			// needed for the pagination plugin; the prarams changed with history.replaceState will not affect the pagination links
			updateLinks(paginationLinks, 'active_tab', activeTab);
		}

		function updateLinks(links, param_name, param_value) {
			for (var i = links.length - 1; i >= 0; i--) {
				let linkHref = links[i].getAttribute('href');
				let linkUrl = new URL(linkHref, 'https://starnav.eu');
				linkUrl.searchParams.set(param_name, param_value);

				links[i].setAttribute('href', linkUrl.pathname + '?' + linkUrl.searchParams);
			}
		}

		//alerts
		$('.alert').on('click', function () {
			var clickedAlert = $(this);

			setTimeout(function () {
				clickedAlert.remove();
			}, 300);
		});

		setTimeout(function () {
			$('.alert').fadeOut(1000, function () {
				$(this).remove();
			});
		}, 3500);

		// disable zoom on input click
		const addMaximumScaleToMetaViewport = () => {
			const el = document.querySelector('meta[name=viewport]');

			if (el !== null) {
				let content = el.getAttribute('content');
				let re = /maximum\-scale=[0-9\.]+/g;

				if (re.test(content)) {
					content = content.replace(re, 'maximum-scale=1.0');
				} else {
					content = [content, 'maximum-scale=1.0'].join(', ');
				}

				el.setAttribute('content', content);
			}
		};

		const disableIosTextFieldZoom = addMaximumScaleToMetaViewport;

		const checkIsIOS = () => /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

		if (checkIsIOS()) {
			disableIosTextFieldZoom();
		}
	}
}
