import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {

    // document.addEventListener("turbo:frame-render", function (e) {
    //   // console.log('turbo:frame-render');
    //   bindContractEvents();
    // });

    bindContractEvents();

    function bindContractEvents(){
      const form = $('#crewman_movement_form');
      if(form == undefined){
        return;
      }

      bindCreateCompanyCheckboxEvents();
      bindCreateVesselCheckboxEvents();
      bindFromChanged();
    }

    function bindCreateVesselCheckboxEvents(){
      let createNewVesselCheckbox = $('#movement-create-new-vessel');
      let createNewVessel = createNewVesselCheckbox.is(":checked");
      toggleCreateNewVesselFields();

      createNewVesselCheckbox.on('change', function(){
        createNewVessel = createNewVesselCheckbox.is(":checked");
        toggleCreateNewVesselFields();
      });

      function toggleCreateNewVesselFields(){
        if(createNewVessel){
          $('.js-new-vessel').removeClass('display-none');
          $('.js-new-vessel').find(':input, select').prop('disabled', false);

          $('.js-select-vessel').addClass('display-none');
          $('.js-select-vessel').find(':input, select').prop('disabled', true);
          // $('#crewman_movement_vessel_id').prop('disabled', 'disabled');
        }else{
          $('.js-new-vessel').addClass('display-none');
          $('.js-new-vessel').find(':input, select').prop('disabled', true);

          $('.js-select-vessel').removeClass('display-none');
          $('.js-select-vessel').find(':input, select').prop('disabled', false);
          // $('#crewman_movement_vessel_id').prop('disabled', false);
        }
      } 
    }

    function bindCreateCompanyCheckboxEvents(){
      let createNewVesselCheckbox = $('#movement-create-new-vessel');
      let createNewCompanyCheckbox = $('#movement-create-new-company');
      let createNewCompany = createNewCompanyCheckbox.is(":checked");
      toggleCreateNewCompanyFields();

      createNewCompanyCheckbox.on('change', function(){
        createNewCompany = createNewCompanyCheckbox.is(":checked");
        toggleCreateNewCompanyFields();
      });

      function toggleCreateNewCompanyFields(){
        if(createNewCompany){
          // new companies can't have vessels
          createNewVesselCheckbox.prop('checked', true);
          createNewVesselCheckbox.trigger('change');

          $('.js-new-company').removeClass('display-none');
          $('.js-select-company').addClass('display-none');
        }else{
          $('.js-new-company').addClass('display-none');
          $('.js-select-company').removeClass('display-none');
        }
      }
    }

    function bindFromChanged(){
      let movementFromInput = $('#crewman_movement_from');
      let movementToInput = $('#crewman_movement_to');
      let movementKindInput = $('#crewman_movement_kind');

      let movementKind = null;

      movementFromInput.on('change', function(){
        movementKind = movementKindInput.val();
        console.log(movementKind);

        if(movementKind == 'travel' && movementToInput.val() == ''){
          movementToInput.val(movementFromInput.val());
        }
      });
    }
  }
}
