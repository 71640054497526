import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="modal"
export default class extends Controller {
  connect() {

    let kindSelect = $('#payroll_item_kind');
    let textInput = $('#payroll_item_text');
    let recupBalancePaymentBaseCheckbox = $('#payroll_item_recup_balance_payment_base_same_as_net_salary');
    let shipsAllowanceCalculationAlgorithmRadio = $('.js-ships-allowance-calculation-algorithm');
    let shipsAllowanceConditionalCalculationAlgorithmRadio = $('.js-ships-allowance-conditional-calculation-algorithm');
    let overtimeCalculationAlgorithmRadio = $('.js-overtime-calculation-algorithm');
    let travelTimeCalculationAlgorithmRadio = $('.js-travel-time-calculation-algorithm');
    // let selectedKindOption = $( "#payroll_item_kind option:selected" );

    let kind = kindSelect.val();
    let text = textInput.val();
    let differentRecupBalancePaymentBase = !recupBalancePaymentBaseCheckbox.is(':checked');
    let shipsAllowanceAlgorithm = shipsAllowanceCalculationAlgorithmRadio.filter(":checked").val();
    let shipsAllowanceConditionalAlgorithm = shipsAllowanceConditionalCalculationAlgorithmRadio.filter(":checked").val();
    let overtimeAlgorithm = overtimeCalculationAlgorithmRadio.filter(":checked").val();
    let travelTimeAlgorithm = travelTimeCalculationAlgorithmRadio.filter(":checked").val();
    // default template inputs
    let currencySelects = $('.js-currency');


    kindSelected(kind, false);

    shipsAllowanceAlgorithmChanged(shipsAllowanceAlgorithm);
    shipsAllowanceConditionalAlgorithmChanged(shipsAllowanceConditionalAlgorithm);

    kindSelect.on('change', function(){
      // selectedKindOption = $( "#payroll_item_kind option:selected" );
      kind = $(this).val();

      kindSelected(kind, true);

      shipsAllowanceAlgorithmChanged(shipsAllowanceAlgorithm);
      shipsAllowanceConditionalAlgorithmChanged(shipsAllowanceConditionalAlgorithm);
      recupBalancePaymentBaseChanged(differentRecupBalancePaymentBase);
      overtimeAlgorithmChanged(overtimeAlgorithm);
      travelTimeAlgorithmChanged(travelTimeAlgorithm);

      let defaultCurrency = 'eur';

      if(kind == 'travel_expenses'){
        defaultCurrency = 'bgn';
      }
      
      if(currencySelects.val() != defaultCurrency){
        currencySelects.val(defaultCurrency);
        currencySelects.trigger('change');
      }

      // Fill the details input with the selected kind
      // if(selectedKindOption.text() == text || text == ''){
      //   selectedKindOption = $( "#payroll_item_kind option:selected" );
      //   textInput.val(selectedKindOption.text());
      // };
    });

    textInput.on('input', function(){
      text = textInput.val();
    });

    recupBalancePaymentBaseChanged(differentRecupBalancePaymentBase);
    overtimeAlgorithmChanged(overtimeAlgorithm);
    travelTimeAlgorithmChanged(travelTimeAlgorithm);

    recupBalancePaymentBaseCheckbox.on('change', function(){
      differentRecupBalancePaymentBase = !$(this).is(':checked');
      recupBalancePaymentBaseChanged(differentRecupBalancePaymentBase);
    })

    shipsAllowanceCalculationAlgorithmRadio.on('change', function(){
      shipsAllowanceAlgorithm = shipsAllowanceCalculationAlgorithmRadio.filter(":checked").val();
      shipsAllowanceAlgorithmChanged(shipsAllowanceAlgorithm);
    })

    shipsAllowanceConditionalCalculationAlgorithmRadio.on('change', function(){
      shipsAllowanceConditionalAlgorithm = shipsAllowanceConditionalCalculationAlgorithmRadio.filter(":checked").val();
      shipsAllowanceConditionalAlgorithmChanged(shipsAllowanceConditionalAlgorithm);
    })

    function shipsAllowanceAlgorithmChangedShared(algorithm, container){
      let percentageContainer = container.find('.js-ships-allowance-percentage-container');
      let dailyRateContainer = container.find('.js-ships-allowance-daily-rate-container');
      let baseEurContainer = container.find('.js-ships-allowance-base-eur-container');
      let daysContainer = container.find('.js-ships-allowance-days-container');

      percentageContainer.addClass('display-none');
      percentageContainer.find(':input, select').prop('disabled', true);

      dailyRateContainer.addClass('display-none');
      dailyRateContainer.find(':input, select').prop('disabled', true);

      baseEurContainer.addClass('display-none');
      baseEurContainer.find(':input, select').prop('disabled', true);

      daysContainer.addClass('display-none');
      daysContainer.find(':input, select').prop('disabled', true);

      if(kind != 'ships_allowance' && kind != 'ships_allowance_conditional'){
        console.log('------------')
        console.log('return')
        console.log(kind)
        console.log(container)
        console.log('------------')
        return;
      }

      console.log('------------')
      console.log('continue - ')
      console.log(kind)
      console.log(container)
      console.log('------------')

      if(algorithm == 'gross_onboard_salary_eur'){
        percentageContainer.removeClass('display-none');
        percentageContainer.find(':input, select').prop('disabled', false);

        daysContainer.removeClass('display-none');
        daysContainer.find(':input, select').prop('disabled', false);

      }else if(algorithm == 'custom_daily_rate'){
        dailyRateContainer.removeClass('display-none');
        dailyRateContainer.find(':input, select').prop('disabled', false);

        daysContainer.removeClass('display-none');
        daysContainer.find(':input, select').prop('disabled', false);

      }else if (algorithm == 'custom_base'){
        percentageContainer.removeClass('display-none');
        percentageContainer.find(':input, select').prop('disabled', false);

        baseEurContainer.removeClass('display-none');
        baseEurContainer.find(':input, select').prop('disabled', false);

        daysContainer.removeClass('display-none');
        daysContainer.find(':input, select').prop('disabled', false);
      }
    }


    function shipsAllowanceConditionalAlgorithmChanged(algorithm){
      if(kind != 'ships_allowance_conditional'){
        return;
      }

      let container = $('.template-ships_allowance_conditional');
      shipsAllowanceAlgorithmChangedShared(algorithm, container)
    }

    function shipsAllowanceAlgorithmChanged(algorithm){
      if(kind != 'ships_allowance'){
        return;
      }

      let container = $('.template-ships_allowance');
      shipsAllowanceAlgorithmChangedShared(algorithm, container)

      // let percentageContainer = container.find('.js-ships-allowance-percentage-container');
      // let dailyRateContainer = container.find('.js-ships-allowance-daily-rate-container');
      // let baseEurContainer = container.find('.js-ships-allowance-base-eur-container');
      // let daysContainer = container.find('.js-ships-allowance-days-container');

      // percentageContainer.addClass('display-none');
      // percentageContainer.find(':input, select').prop('disabled', true);

      // dailyRateContainer.addClass('display-none');
      // dailyRateContainer.find(':input, select').prop('disabled', true);

      // baseEurContainer.addClass('display-none');
      // baseEurContainer.find(':input, select').prop('disabled', true);

      // daysContainer.addClass('display-none');
      // daysContainer.find(':input, select').prop('disabled', true);

      // if(kind != 'ships_allowance' && kind != 'ships_allowance_conditional'){
      //   return;
      // }

      // if(algorithm == 'gross_onboard_salary_eur'){
      //   percentageContainer.removeClass('display-none');
      //   percentageContainer.find(':input, select').prop('disabled', false);

      //   daysContainer.removeClass('display-none');
      //   daysContainer.find(':input, select').prop('disabled', false);

      // }else if(algorithm == 'custom_daily_rate'){
      //   dailyRateContainer.removeClass('display-none');
      //   dailyRateContainer.find(':input, select').prop('disabled', false);

      //   daysContainer.removeClass('display-none');
      //   daysContainer.find(':input, select').prop('disabled', false);

      // }else if (algorithm == 'custom_base'){
      //   percentageContainer.removeClass('display-none');
      //   percentageContainer.find(':input, select').prop('disabled', false);

      //   baseEurContainer.removeClass('display-none');
      //   baseEurContainer.find(':input, select').prop('disabled', false);

      //   daysContainer.removeClass('display-none');
      //   daysContainer.find(':input, select').prop('disabled', false);
      // }
    }

    overtimeCalculationAlgorithmRadio.on('change', function(){
      overtimeAlgorithm = overtimeCalculationAlgorithmRadio.filter(":checked").val();
      overtimeAlgorithmChanged(overtimeAlgorithm);
    })

    function overtimeAlgorithmChanged(algorithm){
      let baseEurContainer = $('#overtime-base-eur-container');

      baseEurContainer.addClass('display-none');
      baseEurContainer.find(':input, select').prop('disabled', true);

      if(kind === undefined || !kind.startsWith('overtime')){
        return;
      }

      if(algorithm == 'custom_base'){
        baseEurContainer.removeClass('display-none');
        baseEurContainer.find(':input, select').prop('disabled', false);

      }else {
        baseEurContainer.addClass('display-none');
        baseEurContainer.find(':input, select').prop('disabled', true);
      }
    }

    travelTimeCalculationAlgorithmRadio.on('change', function(){
      travelTimeAlgorithm = travelTimeCalculationAlgorithmRadio.filter(":checked").val();
      travelTimeAlgorithmChanged(travelTimeAlgorithm);
    })

    function travelTimeAlgorithmChanged(algorithm){
      let baseEurContainer = $('#travel-time-base-eur-container');

      baseEurContainer.addClass('display-none');
      baseEurContainer.find(':input, select').prop('disabled', true);

      if(kind === undefined || !kind.startsWith('travel_time')){
        return;
      }

      if(algorithm == 'custom_base'){
        baseEurContainer.removeClass('display-none');
        baseEurContainer.find(':input, select').prop('disabled', false);

      }else {
        baseEurContainer.addClass('display-none');
        baseEurContainer.find(':input, select').prop('disabled', true);
      }
    }    

    function recupBalancePaymentBaseChanged(differentBase){
      let baseContainer = $('.js-recup-balance-payment-base-container');

      if(differentBase){
        baseContainer.removeClass('display-none');
        baseContainer.find(':input, select').prop('disabled', false);
      }else{
        baseContainer.addClass('display-none');
        baseContainer.find(':input, select').prop('disabled', true);
      }
    }

    function currencySelected(currency, container){
      let displayCurrency = $(container).find('.js-selected-currency');
      let unitSumBgnInput = $(container).find('.js-unit-sum-bgn');
      let unitSumInput = $(container).find('.js-unit-sum-eur');

      let bgnInputs = $(container).find('.js-sum-input-bgn');
      let eurInputs = $(container).find('.js-sum-input-eur');

      displayCurrency.html(currency.toUpperCase());

      // $('.js-payroll-template').addClass('display-none');
      // $('.js-payroll-template').find(':input, select').prop('disabled', true);

      if(currency == 'eur'){
        unitSumBgnInput.prop('disabled', true);
        unitSumBgnInput.parent().addClass('display-none');
        unitSumInput.prop('disabled', false);
        unitSumInput.parent().removeClass('display-none');

        bgnInputs.prop('disabled', true);
        bgnInputs.parent().addClass('display-none');
        eurInputs.prop('disabled', false);
        eurInputs.parent().removeClass('display-none');

      }else if(currency == 'bgn'){
        unitSumBgnInput.prop('disabled', false);
        unitSumBgnInput.parent().removeClass('display-none');
        unitSumInput.prop('disabled', true);
        unitSumInput.parent().addClass('display-none');

        bgnInputs.prop('disabled', false);
        bgnInputs.parent().removeClass('display-none');
        eurInputs.prop('disabled', true);
        eurInputs.parent().addClass('display-none');
      }else{
        unitSumBgnInput.prop('disabled', true);
        unitSumBgnInput.parent().addClass('display-none');
        unitSumInput.prop('disabled', true);
        unitSumInput.parent().addClass('display-none');

        bgnInputs.prop('disabled', true);
        bgnInputs.parent().addClass('display-none');
        eurInputs.prop('disabled', true);
        eurInputs.parent().addClass('display-none');

      };
    };

    currencySelects.on('change', function(){
      let currency = $(this).val();
      let container = $(this).parents('.js-payroll-template');
      currencySelected(currency, container);
      calculateSumWithDeductedTravelAllowance(container);
    });

    function kindSelected(kind, setDefaultCurrency){
      let selectedTemplate = '';

      $('.js-payroll-template').addClass('display-none');
      $('.js-payroll-template').find(':input, select').prop('disabled', true);


      if(['travel_allowance', 'travel_allowance_per_km', 'overtime', 'travel_time',
        'ships_allowance', 'ships_allowance_conditional', 'recup_balance_payment', 'higher_rank_uplift',
        'travel_expenses', 'euronav_adjustment_wages'].includes(kind)){
        selectedTemplate = '.js-payroll-template.template-' + kind;
        // console.log(selectedTemplate);

      }else if(kind.startsWith('overtime')){
        // the overime template
        selectedTemplate = '.js-payroll-template.template-overtime';
      }else if(kind.startsWith('travel_time')){
        // the overime template
        selectedTemplate = '.js-payroll-template.template-travel_time';
      }else if(kind == 'training_allowance'){
        selectedTemplate = '.js-payroll-template.template-training-allowance';
      }else if(kind != ''){
        // the default template
        selectedTemplate = '.js-payroll-template.template-default';
      };

      let currencySelect = $(selectedTemplate).find('.js-currency');
      if(currencySelect.length > 0) {
        if(setDefaultCurrency){
          if(['other_expenses', 'training_expenses'].includes(kind)){
            // bgn by default
            currencySelect.val('bgn');
          }else{
            currencySelect.val('eur');
          }
        }
        // trigger currencySelected
        currencySelected(currencySelect.val(), $(selectedTemplate));
      }

      $(selectedTemplate).find(':input, select').prop('disabled', false);
      $(selectedTemplate).removeClass('display-none');

      calculateSumWithDeductedTravelAllowance(selectedTemplate);
    };

    $('.js-unit-sum-eur, .js-unit-sum-bgn, .js-quantity').on('input', function(){
      let container = $(this).parents('.js-payroll-template');
      calculateSumWithDeductedTravelAllowance(container);
    })

    $('.js-deductable-travel-allowance-sum, .js-deductable-travel-allowance-sum-bgn').on('change', function(){
      let container = $(this).parents('.js-payroll-template');
      calculateSumWithDeductedTravelAllowance(container);
    });

    $('#travel_expenses_deduct_travel_allowance').on('change', function(){
      let container = $(this).parents('.js-payroll-template');
      calculateSumWithDeductedTravelAllowance(container);

      if($(this).is(":checked")){
        container.find('.js-deduct-travel-expenses-info').removeClass('display-none');
      }else {
        container.find('.js-deduct-travel-expenses-info').addClass('display-none');
      }
    });

    function calculateSumWithDeductedTravelAllowance(container){
      let totalSumAfterDeduction = $(container).find('.js-total-sum-after-deduction');
      if(totalSumAfterDeduction.length < 1){
        return;
      }

      let currency = $(container).find('.js-currency').val();
      let quantity = $(container).find('.js-quantity').val();
      if(quantity == undefined || quantity == ''){
        quantity = 0;
      }
      quantity = parseFloat(quantity);

      let unitSum;
      let deductableTravelAllowance;

      if(currency == 'bgn'){
        unitSum = $(container).find('.js-unit-sum-bgn').val();
        deductableTravelAllowance = $(container).find('.js-deductable-travel-allowance-sum-bgn').val();
      }else{
        unitSum = $(container).find('.js-unit-sum-eur').val();
        deductableTravelAllowance = $(container).find('.js-deductable-travel-allowance-sum').val();
      }

      // console.log('currency: ')
      // console.log(currency)

      // console.log('quantity: ')
      // console.log(quantity)

      // console.log('unitSum: ')
      // console.log(unitSum)

      // console.log('deductableTravelAllowance: ')
      // console.log(deductableTravelAllowance)

      if(unitSum == undefined || unitSum == ''){
        unitSum = 0;
      }
      unitSum = parseFloat(unitSum);

      if(deductableTravelAllowance == undefined || deductableTravelAllowance == ''){
        deductableTravelAllowance = 0.0;
      }
      deductableTravelAllowance = parseFloat(deductableTravelAllowance);

      // console.log(deductableTravelAllowance.toFixed(2))

      $('.js-selected-deductable-travel-allowance').html(deductableTravelAllowance.toFixed(2));

      let sumAfterDeduction = (unitSum * quantity) - deductableTravelAllowance;
      if(sumAfterDeduction < 0){
        sumAfterDeduction = 0;
      }
      totalSumAfterDeduction.html(sumAfterDeduction.toFixed(2));

    }

  }
}
